<template>
    <div>
        <a-form :form="form" :rules="rules" v-if="isEditRules">
            <a-row style="display: flex;">
                <a-form-item required prop="visitTime" label="拜访时间" style="margin-right: 10px;">
                    <a-date-picker v-model="form.visitTime" :value="dateValue" @change="dateChange"
                        placeholder="请选择拜访时间" />
                    <a-time-picker v-model="dateMinMon" :default-open-value="moment('00:00:00', 'HH:mm:ss')"
                        @change="dateMinChange" />
                </a-form-item>
                <a-form-item required prop="role" label="拜访人角色">
                    <a-select v-model="form.role" placeholder="请选择拜访人角色" style="width: 200px;margin-right: 10px;">
                        <a-select-option :value="index + 1" :label="item.name" v-for=" (item, index) in roleList"
                            :key="index">
                            {{ item }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item required label="现场照片" style="margin-right: 10px;">
                    <a-upload :beforeUpload="beforeUpload" :file-list="fileList" @change="uploadChange"
                        :multiple="false" :data="postData" :action="qiNiuAction">
                        <a-button v-if="fileList.length == 0"> <a-icon type="upload" style="margin-right: 10px;" />
                            上传现场照片 </a-button>
                    </a-upload>
                </a-form-item>

            </a-row>
            <a-row style="display:flex;">
              <a-form-item required label="拜访简述" prop="content" style="margin-right: 10px;">
                <a-textarea style="width: 350px;" v-model="form.content" placeholder="请输入拜访简述" />
              </a-form-item>
              <a-form-item  label="陪同拜访人员" style="margin-right: 10px;">
                <a-select allowClear v-model="form.staffIdList" show-search mode="multiple" :filter-option="(input, option) => {
                        return (
                        option.componentOptions.children[0].text
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                    }
                        "
                          style="width: 300px"
                          placeholder="请选择陪同拜访人员"
                          :default-value="null"
                          option-label-prop="label"
                >
                  <a-select-option
                      :value="item.id"
                      :label="item.name"
                      v-for="(item, index) in userData"
                      :key="index"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>

            </a-row>
        </a-form>
        <a-button v-if="isEditRules" type="primary" @click="handleSubmit" style="margin-bottom: 10px;margin-top: -10px">
            添加
        </a-button>
        <a-table :columns="columns" bordered :scroll="{ x: 1500 }" :dataSource="dataSource" :loading="tableLoading"
            :pagination="false">
            <template slot="url" slot-scope="text">
                <img :src="text" @click="showModal(text)" style="cursor: pointer;width: 40px;height: 40px;">


            </template>
            <template slot="role" slot-scope="text">
                <span>{{ roleList[text - 1] }}</span>
            </template>
            <template slot="visitTime" slot-scope="text">{{ formatDate(text) }}</template>
            <template slot="createTime" slot-scope="text">{{ formatDate(text) }}</template>
            
            <template slot="action" slot-scope="text">
              <a @click="editChange(text)">编辑</a>
            </template>
        </a-table>
        <a-modal :visible="isImgShow" @cancel="handleCancel" :footer="null">
            <img :src="showimgurl" style="width: 100%" />
        </a-modal>

        <a-modal
        title="编辑陪同拜访人"
        :visible="editVisitTable"
        @cancel="editVisitTable = false"
        @ok="handleVis">
        <a-select allowClear v-model="editObj.staffIdList" show-search mode="multiple" :filter-option="(input, option) => {
                return (
                  option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }
            "
            style="width: 300px"
            placeholder="请选择陪同拜访人员"
            :default-value="null"
            option-label-prop="label"
          >
            <a-select-option
              :value="item.id"
              :label="item.name"
              v-for="(item, index) in userData"
              :key="index"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-modal>
    </div>
</template>
<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request';
import { mapGetters } from 'vuex'

export default {
    props: {
        selectId: {
            type: String,
            default: ""
        },
        isEditRules: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            previewVisible: false,
            isImgShow: false,
            dateValue: "",
            dataSource: [],
            moment,
            form: {},
            confirmLoading: false,
            layout: {
                labelCol: { span: 4 },
                wrapperCol: { span: 10 },
            },
            labelCol: { span: 8 },
            wrapperCol: { span: 24 },
            columns: [
                { title: '序号', customRender: (text, record, index) => index + 1, align: 'center', width: 80 },
                {
                    title: '创建时间', dataIndex: 'createTime', align: 'center', scopedSlots: { customRender: 'createTime' }, sorter: (a, b) => new Date(b.createTime) - new Date(a.createTime),
                    defaultSortOrder: 'ascend',width:'15%'
                },
                { title: '拜访时间', dataIndex: 'visitTime', align: 'center', scopedSlots: { customRender: 'visitTime' } ,width:'15%'},
                { title: '陪同拜访人', dataIndex: 'accompanyStaffName', align: 'center', scopedSlots: { customRender: 'accompanyStaffName' },width:'15%' },
                { title: '拜访角色', dataIndex: 'role', align: 'center', scopedSlots: { customRender: 'role' },width:'15%' },
                { title: '拜访简述', dataIndex: 'content', align: 'center' },
                { title: '现场照片', dataIndex: 'url', align: 'center', scopedSlots: { customRender: 'url' },width:'15%' },
                { title: '操作',  align: 'center', scopedSlots: { customRender: 'action' },fixed: 'right',width:100},
            ],
            rules: {
                visitTime: [{ required: true, message: '请选择拜访日期', trigger: 'change' }],
                content: [{ required: true, message: '请输入拜访简述', trigger: 'blur' }],
                role: [{ required: true, message: '请选择拜访角色', trigger: 'change' }],
            },

            qiNiuAction: 'https://upload-z1.qiniup.com',
            qiNiuUploadData: {
                key: '',
                token: ''
            },
            getQiniuTokenIng: false,
            postData: {
                token: ''
            },
            fileList: [],
            isShowUpload: true,
            roleList: ["未知", "总负责人", "重要决策人", "办事人员", "其他"],
            showimgurl: "",
            dateMin: "",
            dateMinMon: null,
            userData:[],
            editVisitTable:false,
            editObj:{}
        }
    },
    watch: {
        selectId: {
            immediate: true, // 立即执行一次
            handler() {
                this.getData()
            }
        }
    },
    mounted() {
        this.getData()
        this.getQiniuToken()
        this.getUserList()
    },
    computed:{
        ...mapGetters('account', ['user']),
    },
    methods: {
        editChange(record){
            this.editVisitTable = true;
            this.editObj = record;
        },
        handleVis(){
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/visitrecord/update/' + this.editObj.customerId, 'post', this.editObj).then((res) => {
                    if (res.data.code == 200) {
                        this.$message.success('修改成功！')
                        this.editVisitTable = false;
                        this.getData()
                    } else {
                        this.$message.error(res.data.message)
                    }
                })
        },
        getUserList() {
            request(process.env.VUE_APP_API_BASE_URL + "sfa/staff/list/1", "get").then((res) => {
                if (res.data.code == 200) {
                    this.userData = res.data.data;
                    this.userData.forEach((item,index)=>{
                        if(item.id == this.$store.state.account.user.id) {
                            this.userData.splice(index,1)
                        }
                    })
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        showModal(text) {
            this.showimgurl = text;
            this.isImgShow = true;
        },
        handleCancel() {
            this.isImgShow = false;
        },
        uploadChange(e) {
            // this.fileList = e.fileList
            this.fileList = [...e.fileList];
            this.form.url = e.file.response.url;
            this.isShowUpload = false;
        },
        handleSubmit() {
            if (!this.form.visitTime || !this.form.content || !this.form.url || !this.dateMin) {
                this.$message.warning('请补全信息！')
            } else {
                this.form.visitType = 2;
                this.form.visitTime = new Date(this.form.visitTime + ' ' + this.dateMin)
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/visitrecord/save/' + this.selectId, 'post', this.form).then((res) => {
                    if (res.data.code == 200) {
                        this.$message.success('添加成功！')
                        this.dateValue = ""
                        this.dateMin = ""
                        this.dateMinMon = ""
                        this.form = {}
                        this.fileList = []
                        this.getData()
                    } else {
                        this.$message.error(res.data.message)
                    }
                })
            }
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                this.$message.error('上传文件类型有误!');
            }
            const isLt30M = file.size / 1024 / 1024 < 30;
            if (!isLt30M) {
                this.$message.error('Image must be smaller than 30MB!');
            }
            return isJpgOrPng && isLt30M;
        },
        getQiniuToken() {
            if (!this.getQiniuTokenIng) {
                this.getQiniuTokenIng = true
                this.loading = true
                this.loadingText = 'token加载中'
                request(process.env.VUE_APP_API_BASE_URL + 'srm/qiniu/token/yjhp-hb', 'get').then((res) => {
                    if (res.data.code == 200) {
                        this.postData.token = res.data.data
                        // this.postData.key = 'sfa/upload' + Math.floor(Math.random() * 100) + new Date().valueOf()
                        this.getQiniuTokenIng = false
                    }
                })
            }
        },
        dateChange(date, dateString) {
            this.form.visitTime = dateString;
            this.dateValue = dateString
        },
        dateMinChange(date, dateString) {
            this.dateMin = dateString;
            this.dateMinMon = date
        },
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            return result;
        },
        getData() {
            this.tableLoading = true
            if (!this.selectId) {
                return false;
            }
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/visitrecord/list/' + this.selectId + '/2', 'get').then(res => {
                this.tableLoading = false;
                if (res.data.code == 200) {
                    this.dataSource = res.data.data
                } else {
                    this.$message.error(res.data.message)
                }
            })
        }
    }
}
</script>