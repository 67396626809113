<template>
    <div>
        <a-form :form="form" :rules="rules" v-if="isEditRules" style="display: flex;">
            <a-form-item required prop="visitTime" label="沟通时间" style="margin-right: 10px;">
              <a-date-picker :show-time="{ format: 'HH:mm', defaultValue: moment('00:00', 'HH:mm') }" v-model="form.visitTime" :value="dateValue" @change="dateChange" placeholder="请选择沟通时间" />
            </a-form-item>
            <a-form-item required label="沟通简述" prop="content" >
            <a-textarea style="width: 400px;" v-model="form.content" placeholder="请输入沟通简述"  />
            </a-form-item>
            <a-form-item>
                <a-button type="primary" @click="handleSubmit" style="margin-top: 40px;margin-left: 10px;">
                    添加
                </a-button>
            </a-form-item>
        </a-form>
        <a-table :columns="columns" bordered  :scroll="{ x: 1500 }" :dataSource="dataSource" :loading="tableLoading" :pagination="false">
          <template slot="url" slot-scope="text">
            <img :src="text" @click="showModal(text)" style="cursor: pointer;width: 40px;height: 40px;">
          </template>
          <template slot="role" slot-scope="text">
            <span>{{ roleList[text-1] }}</span>
          </template>
          <template slot="visitTime" slot-scope="text">{{ formatDate(text) }}</template>
          <template slot="createTime" slot-scope="text">{{ formatDate(text) }}</template>
        </a-table>
        <a-modal :visible="isImgShow" @cancel="handleCancel" :footer="null" >
                <img :src="showimgurl" style="width: 100%"  />
            </a-modal>
    </div>
</template>
<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import {request} from '@/utils/request';

export default {
    props:{
        selectId:{
            type:String,
            default:""
        },
        isEditRules:{
            type:Boolean,
            default:true
        }
    },
    data() {
        return {
            previewVisible: false,
            isImgShow:false,
            dateValue:"",
            dataSource:[],
            moment,
            form:{},
            confirmLoading: false,
            layout: {
                labelCol: { span: 4 },
                wrapperCol: { span: 10 },
            },
            labelCol: {span: 8},
            wrapperCol: {span: 24},
            columns:[
                {title: '序号', customRender: (text, record, index) => index + 1, align: 'center',width:80},
                {title: '创建时间', dataIndex: 'createTime', align: 'center', scopedSlots: { customRender: 'createTime' },sorter: (a, b) => new Date(b.createTime) - new Date(a.createTime),
          defaultSortOrder: 'ascend',width:350},
                {title: '沟通时间', dataIndex: 'visitTime', align: 'center', scopedSlots: { customRender: 'visitTime' },width:350},
                // {title: '拜访角色', dataIndex: 'role', align: 'center', scopedSlots: { customRender: 'role' }},
                {title: '沟通简述', dataIndex: 'content', align: 'center'},
                // {title: '现场照片', dataIndex: 'url', align: 'center', scopedSlots: { customRender: 'url' }},
            ],
            rules: {
                visitTime: [{required: true, message: '请选择拜访日期', trigger: 'change'}],
                content: [{required: true, message: '请输入拜访简述', trigger: 'blur'}],
                role: [{required: true, message: '请选择拜访角色', trigger: 'change'}],
            },

            qiNiuAction: 'https://upload-z1.qiniup.com',
            qiNiuUploadData: {
                key: '',
                token: ''
            },
            getQiniuTokenIng:false,
            postData:{
                token:''
            },
            fileList:[],
            isShowUpload:true,
            roleList: ["未知", "总负责人", "重要决策人", "办事人员", "其他"],
            showimgurl:""
        }
    },
    watch: {
    selectId: {
      immediate: true, // 立即执行一次
      handler() {
        this.getData()
      }
    }
  },
    mounted(){
        this.getData()
        this.getQiniuToken()
    },
    methods:{
        showModal(text) {
            this.showimgurl=text;
            this.isImgShow = true;
        },
        handleCancel() {
            this.isImgShow = false;
        },
        uploadChange(e) {
            console.log(e,'eeeee')
            // this.fileList = e.fileList
            this.fileList = [...e.fileList];
            this.form.url = e.file.response.url;
            this.isShowUpload = false;
        },
        handleSubmit() {
            if(!this.form.visitTime || !this.form.content ) {
                this.$message.warning('请补全信息！')
            } else {
                this.form.visitType = 1;
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/visitrecord/save/'+this.selectId, 'post',this.form).then((res) => {
                    if(res.data.code == 200) {
                        this.$message.success('添加成功！')
                        this.dateValue = ""
                        this.form = {}
                        this.fileList = []
                        this.getData()
                    } else {
                        this.$message.error(res.data.message)
                    }
                })
            }
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                this.$message.error('You can only upload JPG file!');
            }
            const isLt30M = file.size / 1024 / 1024 < 30;
            if (!isLt30M) {
                this.$message.error('Image must be smaller than 30MB!');
            }
            return isJpgOrPng && isLt30M;
        },
        getQiniuToken() {
                if (!this.getQiniuTokenIng) {
                this.getQiniuTokenIng = true
                this.loading = true
                this.loadingText = 'token加载中'
                request(process.env.VUE_APP_API_BASE_URL + 'srm/qiniu/token/yjhp-hb','get').then((res) => {
                    if (res.data.code == 200) {
                    this.postData.token = res.data.data
                    // this.postData.key = 'sfa/upload' + Math.floor(Math.random() * 100) + new Date().valueOf()
                    this.getQiniuTokenIng = false
                    } 
                })
            }
        },
      dateChange(date, dateString) {
        this.form.visitTime = date;
        this.dateValue = dateString
      },
      formatDate(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        let result = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return result;
       },
      getData() {
        this.tableLoading = true
        if(!this.selectId) {
            return false;
        }
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/visitrecord/list/'+this.selectId + '/1', 'get').then(res => {
            this.tableLoading = false;
            if (res.data.code == 200) {
                this.dataSource = res.data.data
            } else {
                this.$message.error(res.data.message)
            }
       })
      }  
    }
}
</script>